// all your existing imports stay the same
import React, { useEffect, useState, useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { supabase } from "../../lib/supabaseClient";
import CameraCapture from "../../components/CameraCapture";

function EditFormPage() {
  const { formId } = useParams();
  const navigate = useNavigate();
  const [formName, setFormName] = useState("");
  const [fields, setFields] = useState([]);
  const [newFieldType, setNewFieldType] = useState("text");

  const fetchForm = useCallback(async () => {
    const { data, error } = await supabase
      .from("forms")
      .select("*")
      .eq("id", formId)
      .single();

    if (error) {
      alert("Error loading form: " + error.message);
    } else {
      const initializedFields = (data.fields || []).map((field) => ({
        ...field,
        bulkMode: false,
        bulkText: field.options ? field.options.join(", ") : "",
      }));
      setFormName(data.name);
      setFields(initializedFields);
    }
  }, [formId]);

  useEffect(() => {
    fetchForm();
  }, [fetchForm]);

  const updateField = (index, key, value) => {
    const updated = [...fields];
    updated[index][key] = value;
    setFields(updated);
  };

  const updateOption = (index, optIndex, value) => {
    const updated = [...fields];
    updated[index].options[optIndex] = value;
    updated[index].bulkText = updated[index].options.join(", ");
    setFields(updated);
  };

  const addOption = (index) => {
    const updated = [...fields];
    updated[index].options.push("New Option");
    updated[index].bulkText = updated[index].options.join(", ");
    setFields(updated);
  };

  const removeOption = (index, optIndex) => {
    const updated = [...fields];
    updated[index].options.splice(optIndex, 1);
    updated[index].bulkText = updated[index].options.join(", ");
    setFields(updated);
  };

  const updateOptionMap = (index, parentValue, optionsString) => {
    const updated = [...fields];
    updated[index].optionMap = {
      ...updated[index].optionMap,
      [parentValue]: optionsString.split(/[\n,]+/), // split by newlines or commas
    };
    setFields(updated);
  };

  const handleBulkChange = (index, value) => {
    const updated = [...fields];
    updated[index].bulkText = value;
    updated[index].options = value
      .split(/[\n,]+/) // split by newline OR comma
      .map((opt) => opt.trim())
      .filter(Boolean); // remove empty strings
    setFields(updated);
  };

  const toggleBulkMode = (index) => {
    const updated = [...fields];
    updated[index].bulkMode = !updated[index].bulkMode;
    setFields(updated);
  };

  const addField = () => {
    setFields([
      ...fields,
      {
        label: "",
        type: newFieldType,
        options: ["dropdown", "radio", "checkbox", "searchbox"].includes(
          newFieldType
        )
          ? ["Option 1"]
          : [],
        optionMap: {},
        branchingField: "",
        imageURL: null,
        required: false,
        bulkMode: false,
        bulkText: "Option 1",
      },
    ]);
  };

  const removeField = (index) => {
    const updated = [...fields];
    updated.splice(index, 1);
    setFields(updated);
  };

  const handleSave = async () => {
    if (!formName || fields.length === 0) {
      alert("Form name and at least one field is required!");
      return;
    }

    for (const field of fields) {
      if (field.required && !field.label) {
        alert(`Field "${field.label}" is required but has no label`);
        return;
      }
    }

    const cleanedFields = fields.map(({ bulkMode, bulkText, ...rest }) => rest);

    const { error } = await supabase
      .from("forms")
      .update({ name: formName, fields: cleanedFields })
      .eq("id", formId);

    if (error) {
      alert("Error saving form: " + error.message);
    } else {
      navigate("/admin");
    }
  };

  return (
    <div style={{ padding: 20 }}>
      <h2>Edit Form</h2>

      <div style={{ marginBottom: 20 }}>
        <label>
          <strong>Form Name: </strong>
          <input
            type="text"
            value={formName}
            onChange={(e) => setFormName(e.target.value)}
            style={{ padding: 5 }}
          />
        </label>
      </div>

      <div style={{ marginBottom: 20 }}>
        <label>
          <strong>Add New Field: </strong>
          <select
            value={newFieldType}
            onChange={(e) => setNewFieldType(e.target.value)}
            style={{ padding: 5, marginRight: 10 }}
          >
            <option value="text">Text</option>
            <option value="textarea">Textarea</option>
            <option value="dropdown">Dropdown</option>
            <option value="radio">Radio</option>
            <option value="checkbox">Checkbox</option>
            <option value="searchbox">Search Box</option>
            <option value="file">File Upload</option>
            <option value="camera">Camera</option>
            <option value="location">Location</option>
            <option value="signature">Signature</option>
          </select>
        </label>
        <button onClick={addField}>Add Field</button>
      </div>

      <table
        border="1"
        cellPadding="10"
        style={{ width: "100%", marginBottom: 20 }}
      >
        <thead>
          <tr>
            <th>Label</th>
            <th>Type</th>
            <th>Required</th>
            <th>Options</th>
            <th>Branching</th>
            <th>Camera</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {fields.map((field, i) => (
            <tr key={i}>
              <td>
                <input
                  type="text"
                  value={field.label}
                  onChange={(e) => updateField(i, "label", e.target.value)}
                  style={{ width: "100%" }}
                />
              </td>

              <td>
                <select
                  value={field.type}
                  onChange={(e) => updateField(i, "type", e.target.value)}
                >
                  <option value="text">Text</option>
                  <option value="textarea">Textarea</option>
                  <option value="dropdown">Dropdown</option>
                  <option value="radio">Radio</option>
                  <option value="checkbox">Checkbox</option>
                  <option value="searchbox">Search Box</option>
                  <option value="file">File</option>
                  <option value="camera">Camera</option>
                  <option value="location">Location</option>
                  <option value="signature">Signature</option>
                </select>
              </td>

              <td style={{ textAlign: "center" }}>
                <input
                  type="checkbox"
                  checked={field.required || false}
                  onChange={(e) => updateField(i, "required", e.target.checked)}
                />
              </td>

              <td>
                {["dropdown", "radio", "checkbox", "searchbox"].includes(
                  field.type
                ) ? (
                  <div
                    style={{ display: "flex", flexDirection: "column", gap: 4 }}
                  >
                    <label>
                      <input
                        type="checkbox"
                        checked={field.bulkMode}
                        onChange={() => toggleBulkMode(i)}
                      />{" "}
                      Use bulk input
                    </label>
                    {field.bulkMode ? (
                      <textarea
                        style={{ width: "100%" }}
                        placeholder="Enter comma-separated options"
                        value={field.bulkText || ""}
                        onChange={(e) => handleBulkChange(i, e.target.value)}
                      />
                    ) : (
                      <>
                        {field.options?.map((opt, j) => (
                          <div key={j}>
                            <input
                              type="text"
                              value={opt}
                              onChange={(e) =>
                                updateOption(i, j, e.target.value)
                              }
                              style={{ width: "80%" }}
                            />
                            <button onClick={() => removeOption(i, j)}>
                              X
                            </button>
                          </div>
                        ))}
                        <button onClick={() => addOption(i)}>Add Option</button>
                      </>
                    )}
                  </div>
                ) : (
                  "-"
                )}
              </td>

              <td>
                {["dropdown", "searchbox"].includes(field.type) && (
                  <div
                    style={{ display: "flex", flexDirection: "column", gap: 5 }}
                  >
                    <select
                      value={field.branchingField || ""}
                      onChange={(e) =>
                        updateField(i, "branchingField", e.target.value)
                      }
                    >
                      <option value="">Select parent field</option>
                      {fields
                        .filter((_, idx) => idx !== i)
                        .map((f, idx) => (
                          <option key={idx} value={f.label}>
                            {f.label}
                          </option>
                        ))}
                    </select>

                    {field.branchingField && (
                      <>
                        {(() => {
                          const parentField = fields.find(
                            (f) => f.label === field.branchingField
                          );
                          if (!parentField) return null;

                          // Gather possible parent values to branch from
                          let parentOptions = [];
                          if (
                            parentField.optionMap &&
                            Object.keys(parentField.optionMap).length > 0
                          ) {
                            parentOptions = Object.values(
                              parentField.optionMap
                            ).flat();
                          } else if (parentField.options) {
                            parentOptions = parentField.options;
                          }

                          // Remove duplicates
                          parentOptions = [...new Set(parentOptions)];

                          return parentOptions.map((opt, k) => (
                            <div key={k}>
                              <label>
                                {field.branchingField} = {opt}
                              </label>
                              <textarea
                                placeholder="Enter one option per line or comma-separated"
                                value={field.optionMap?.[opt]?.join("\n") || ""}
                                onChange={(e) =>
                                  updateOptionMap(i, opt, e.target.value)
                                }
                                style={{
                                  width: "100%",
                                  minHeight: "60px",
                                  padding: "5px",
                                  fontFamily: "inherit",
                                  resize: "vertical",
                                  whiteSpace: "pre-wrap",
                                }}
                              />
                            </div>
                          ));
                        })()}
                      </>
                    )}
                  </div>
                )}
              </td>

              <td>
                {field.type === "camera" && (
                  <div
                    style={{ display: "flex", flexDirection: "column", gap: 5 }}
                  >
                    <CameraCapture
                      onUpload={(file) => {
                        updateField(i, "imageURL", URL.createObjectURL(file));
                      }}
                    />
                    {field.imageURL && (
                      <a href={field.imageURL} target="_blank" rel="noreferrer">
                        View
                      </a>
                    )}
                  </div>
                )}
              </td>

              <td>
                <button onClick={() => removeField(i)}>Delete</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <button
        onClick={handleSave}
        style={{ padding: "10px 20px", fontSize: "1rem" }}
      >
        Save Changes
      </button>
    </div>
  );
}

export default EditFormPage;
