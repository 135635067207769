import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import { supabase } from "../../lib/supabaseClient";
import CameraCapture from "../../components/CameraCapture";
import Swal from "sweetalert2";
import "./FormFiller.css";

const SignaturePad = ({ value, onChange }) => {
  const canvasRef = useRef(null);
  const [isDrawing, setIsDrawing] = useState(false);
  const [dimensions, setDimensions] = useState({
    width: 300,
    height: 150,
  });

  // Auto-resize effect
  useEffect(() => {
    const updateSize = () => {
      const maxWidth = Math.min(300, window.innerWidth - 40); // 40px padding
      const ratio = 0.5; // width:height ratio (2:1)
      setDimensions({
        width: maxWidth,
        height: maxWidth * ratio,
      });
    };

    updateSize();
    window.addEventListener("resize", updateSize);
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  // Drawing initialization
  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const ctx = canvas.getContext("2d");
    ctx.lineWidth = 2;
    ctx.strokeStyle = "#000";
    ctx.lineCap = "round";

    if (value) {
      const img = new Image();
      img.src = value;
      img.onload = () =>
        ctx.drawImage(img, 0, 0, dimensions.width, dimensions.height);
    }
  }, [value, dimensions]);

  // Drawing handlers (mouse + touch)
  const startDrawing = (e) => {
    const canvas = canvasRef.current;
    const rect = canvas.getBoundingClientRect();
    const ctx = canvas.getContext("2d");
    const x = (e.clientX || e.touches[0].clientX) - rect.left;
    const y = (e.clientY || e.touches[0].clientY) - rect.top;

    ctx.beginPath();
    ctx.moveTo(x, y);
    setIsDrawing(true);
    e.preventDefault();
  };

  const draw = (e) => {
    if (!isDrawing) return;
    const canvas = canvasRef.current;
    const rect = canvas.getBoundingClientRect();
    const ctx = canvas.getContext("2d");
    const x = (e.clientX || e.touches[0].clientX) - rect.left;
    const y = (e.clientY || e.touches[0].clientY) - rect.top;

    ctx.lineTo(x, y);
    ctx.stroke();
    if (e.type === "touchmove") e.preventDefault();
  };

  const endDrawing = () => {
    const canvas = canvasRef.current;
    const dataURL = canvas.toDataURL();
    onChange(dataURL);
    setIsDrawing(false);
  };

  const clearSignature = () => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    ctx.clearRect(0, 0, dimensions.width, dimensions.height);
    onChange(null);
  };

  return (
    <div className="signature-container">
      <canvas
        ref={canvasRef}
        width={dimensions.width}
        height={dimensions.height}
        onMouseDown={startDrawing}
        onTouchStart={startDrawing}
        onMouseMove={draw}
        onTouchMove={draw}
        onMouseUp={endDrawing}
        onTouchEnd={endDrawing}
        style={{
          border: "1px solid #000",
          background: "#fff",
          touchAction: "none",
        }}
      />
      <div className="signature-actions">
        <button type="button" onClick={clearSignature} className="button small">
          Clear
        </button>
      </div>
      {value && <p className="signature-notice">✓ Signature captured</p>}
    </div>
  );
};

function FormFiller() {
  const { formId } = useParams();
  const [form, setForm] = useState(null);
  const [answers, setAnswers] = useState({});
  const [fileUploads, setFileUploads] = useState({});
  const [loading, setLoading] = useState(true);
  const [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    const fetchForm = async () => {
      const { data, error } = await supabase
        .from("forms")
        .select("*")
        .eq("id", formId)
        .single();

      if (error) {
        Swal.fire("Error", "Failed to load form: " + error.message, "error");
      } else {
        setForm(data);
      }
      setLoading(false);
    };

    fetchForm();
  }, [formId]);

  const handleLocationClick = async (label) => {
    if (!navigator.geolocation) {
      Swal.fire(
        "Error",
        "Geolocation is not supported by your browser",
        "error"
      );
      return;
    }

    try {
      const position = await new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(resolve, reject);
      });

      const { latitude, longitude } = position.coords;
      const mapsLink = `https://www.google.com/maps?q=${latitude},${longitude}`;
      const locationValue = `${latitude}, ${longitude} (${mapsLink})`;

      handleChange(label, locationValue);
      Swal.fire("Success", "Location captured!", "success");
    } catch (error) {
      Swal.fire("Error", "Failed to get location: " + error.message, "error");
    }
  };

  const handleChange = (label, value) => {
    setAnswers((prev) => ({ ...prev, [label]: value }));
  };

  const handleCheckboxChange = (label, option) => {
    const current = answers[label] || [];
    const updated = current.includes(option)
      ? current.filter((v) => v !== option)
      : [...current, option];
    setAnswers((prev) => ({ ...prev, [label]: updated }));
  };

  const shouldShowField = (field) => {
    if (!field.branchingField) return true;

    const parentValue = answers[field.branchingField];
    if (!parentValue || !field.optionMap) return false;

    const options = field.optionMap[parentValue];
    return Array.isArray(options) && options.length > 0;
  };

  const getFilteredOptions = (field) => {
    if (!field.branchingField || !field.optionMap) {
      return field.options || [];
    }

    const parentValue = answers[field.branchingField];
    return field.optionMap[parentValue] || [];
  };

  const uploadFile = async (file) => {
    const filePath = `uploads/${Date.now()}_${file.name}`;
    const { error } = await supabase.storage
      .from("platipus-uploads")
      .upload(filePath, file, {
        cacheControl: "3600",
        upsert: false,
      });

    if (error) {
      console.error("Upload error:", error);
      return null;
    }
    return filePath;
  };

  const validateForm = () => {
    const requiredFields = form.fields.filter((f) => f.required);
    for (const field of requiredFields) {
      const value = answers[field.label];
      if (
        (field.type === "checkbox" && (!value || value.length === 0)) ||
        (field.type === "file" && !fileUploads[field.label]) ||
        (field.type === "camera" && !fileUploads[field.label]) ||
        (!["checkbox", "file", "camera"].includes(field.type) && !value)
      ) {
        Swal.fire("Oops!", `Field "${field.label}" is required.`, "error");
        return false;
      }
    }
    return true;
  };

  const handleSubmit = async () => {
    if (!validateForm()) return;

    const updatedAnswers = { ...answers };
    for (const [label, file] of Object.entries(fileUploads)) {
      const uploadedPath = await uploadFile(file);
      if (uploadedPath) {
        updatedAnswers[label] = uploadedPath;
      }
    }

    const { error } = await supabase.from("responses").insert([
      {
        form_id: formId,
        answers: updatedAnswers,
        created_at: new Date().toISOString(),
      },
    ]);

    if (error) {
      Swal.fire("Error", "Failed to submit: " + error.message, "error");
    } else {
      setSubmitted(true);
      setAnswers({});
      setFileUploads({});
    }
  };

  if (loading) return <p className="loading">Loading...</p>;
  if (!form) return <p className="loading">Form not found.</p>;

  if (submitted) {
    return (
      <div className="form-container text-center">
        <h3 className="success-message">✅ Form berhasil dikirim!</h3>
        <button
          className="button"
          onClick={() => {
            setSubmitted(false);
            setAnswers({});
            setFileUploads({});
          }}
        >
          Isi Lagi
        </button>
      </div>
    );
  }

  return (
    <div className="form-container">
      <h2 className="form-title">{form.name}</h2>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        {form.fields.map((field, i) =>
          shouldShowField(field) ? (
            <div key={i} className="form-group">
              <label className="form-label">
                {field.label}
                {field.required && <span className="required">*</span>}
              </label>

              {field.type === "text" && (
                <input
                  type="text"
                  value={answers[field.label] || ""}
                  onChange={(e) => handleChange(field.label, e.target.value)}
                  className="form-input"
                />
              )}

              {field.type === "textarea" && (
                <textarea
                  value={answers[field.label] || ""}
                  onChange={(e) => handleChange(field.label, e.target.value)}
                  className="form-input"
                />
              )}

              {field.type === "file" && (
                <input
                  type="file"
                  onChange={(e) => {
                    const file = e.target.files[0];
                    if (file) {
                      setFileUploads((prev) => ({
                        ...prev,
                        [field.label]: file,
                      }));
                      handleChange(field.label, file.name);
                    }
                  }}
                  className="form-input"
                />
              )}

              {field.type === "camera" && (
                <CameraCapture
                  onUpload={(file) => {
                    setFileUploads((prev) => ({
                      ...prev,
                      [field.label]: file,
                    }));
                    handleChange(field.label, file.name);
                  }}
                />
              )}

              {field.type === "dropdown" && (
                <select
                  value={answers[field.label] || ""}
                  onChange={(e) => handleChange(field.label, e.target.value)}
                  className="form-input"
                >
                  <option value="">-- Pilih --</option>
                  {getFilteredOptions(field).map((opt, idx) => (
                    <option key={idx} value={opt}>
                      {opt}
                    </option>
                  ))}
                </select>
              )}

              {field.type === "searchbox" && (
                <>
                  <input
                    list={`${field.label}-list`}
                    value={answers[field.label] || ""}
                    onChange={(e) => handleChange(field.label, e.target.value)}
                    className="form-input"
                    placeholder="Cari atau ketik..."
                  />
                  <datalist id={`${field.label}-list`}>
                    {getFilteredOptions(field).map((opt, idx) => (
                      <option key={idx} value={opt} />
                    ))}
                  </datalist>
                </>
              )}

              {field.type === "radio" &&
                field.options.map((opt, idx) => (
                  <div key={idx} className="form-radio-option">
                    <input
                      type="radio"
                      name={field.label}
                      value={opt}
                      checked={answers[field.label] === opt}
                      onChange={() => handleChange(field.label, opt)}
                    />
                    <label>{opt}</label>
                  </div>
                ))}
              {field.type === "location" && (
                <div>
                  <button
                    type="button"
                    className="button"
                    onClick={() => handleLocationClick(field.label)}
                  >
                    Get My Location
                  </button>
                  {answers[field.label] && (
                    <div className="location-display">
                      <p>Coordinates: {answers[field.label].split(" (")[0]}</p>
                      <a
                        href={
                          answers[field.label].match(
                            /\((https?:\/\/[^)]+)\)/
                          )[1]
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        View on Google Maps
                      </a>
                    </div>
                  )}
                </div>
              )}
              {field.type === "checkbox" &&
                field.options.map((opt, idx) => (
                  <div key={idx} className="form-checkbox-option">
                    <input
                      type="checkbox"
                      value={opt}
                      checked={answers[field.label]?.includes(opt) || false}
                      onChange={() => handleCheckboxChange(field.label, opt)}
                    />
                    <label>{opt}</label>
                  </div>
                ))}
              {field.type === "signature" && (
                <div className="form-group">
                  <SignaturePad
                    value={answers[field.label] || null}
                    onChange={(value) => handleChange(field.label, value)}
                  />
                </div>
              )}
            </div>
          ) : null
        )}

        <button type="submit" className="button">
          Submit
        </button>
      </form>
    </div>
  );
}

export default FormFiller;
