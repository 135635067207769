import React, { useState } from "react";
import { supabase } from "../../lib/supabaseClient";
import CameraCapture from "../../components/CameraCapture";
import { useNavigate } from "react-router-dom";
import "./FormBuilder.css";

function FormBuilder() {
  const [formName, setFormName] = useState("");
  const [fields, setFields] = useState([]);
  const [newFieldType, setNewFieldType] = useState("text");
  const navigate = useNavigate();

  const addField = () => {
    setFields([
      ...fields,
      {
        label: "",
        type: newFieldType,
        options: ["dropdown", "radio", "checkbox", "searchbox"].includes(
          newFieldType
        )
          ? ["Option 1"]
          : [],
        optionMap: {},
        branchingField: "",
        imageURL: null,
        required: false,
        bulkMode: false,
        bulkText: "Option 1",
      },
    ]);
  };

  const updateField = (index, key, value) => {
    const updated = [...fields];
    updated[index][key] = value;
    setFields(updated);
  };

  const updateOption = (index, optIndex, value) => {
    const updated = [...fields];
    updated[index].options[optIndex] = value;
    updated[index].bulkText = updated[index].options.join(", ");
    setFields(updated);
  };

  const addOption = (index) => {
    const updated = [...fields];
    updated[index].options.push("New Option");
    updated[index].bulkText = updated[index].options.join(", ");
    setFields(updated);
  };

  const removeOption = (index, optIndex) => {
    const updated = [...fields];
    updated[index].options.splice(optIndex, 1);
    updated[index].bulkText = updated[index].options.join(", ");
    setFields(updated);
  };

  const updateOptionMap = (index, parentValue, optionsString) => {
    const updated = [...fields];
    updated[index].optionMap = {
      ...updated[index].optionMap,
      [parentValue]: optionsString.split(/[\n,]+/),
    };
    setFields(updated);
  };

  const handleBulkChange = (index, value) => {
    const updated = [...fields];
    updated[index].bulkText = value;
    updated[index].options = value
      .split(/[\n,]+/)
      .map((opt) => opt.trim())
      .filter(Boolean);
    setFields(updated);
  };

  const toggleBulkMode = (index) => {
    const updated = [...fields];
    updated[index].bulkMode = !updated[index].bulkMode;
    setFields(updated);
  };

  const removeField = (index) => {
    const updated = [...fields];
    updated.splice(index, 1);
    setFields(updated);
  };

  const saveForm = async () => {
    if (!formName || fields.length === 0) {
      alert("Form name and at least one field is required!");
      return;
    }

    for (const field of fields) {
      if (field.required && !field.label) {
        alert(`Field "${field.label}" is required but has no label`);
        return;
      }
    }

    const cleanedFields = fields.map(({ bulkMode, bulkText, ...rest }) => rest);

    const { error } = await supabase.from("forms").insert([
      {
        name: formName,
        fields: cleanedFields,
        created_at: new Date().toISOString(),
      },
    ]);

    if (error) {
      alert("Error saving form: " + error.message);
    } else {
      navigate("/admin");
    }
  };

  return (
    <div className="form-builder">
      <h2>Create New Form</h2>

      <div className="form-name-section">
        <label>
          <strong>Form Name: </strong>
          <input
            type="text"
            className="input"
            placeholder="Form Name"
            value={formName}
            onChange={(e) => setFormName(e.target.value)}
          />
        </label>
      </div>

      <div className="add-field-section">
        <h4>Add Field</h4>
        <div className="add-field-row">
          <select
            className="select"
            value={newFieldType}
            onChange={(e) => setNewFieldType(e.target.value)}
          >
            <option value="text">Text</option>
            <option value="textarea">Textarea</option>
            <option value="dropdown">Dropdown</option>
            <option value="radio">Radio</option>
            <option value="checkbox">Checkbox</option>
            <option value="searchbox">Search Box</option>
            <option value="file">File Upload</option>
            <option value="camera">Camera</option>
            <option value="location">Location</option>
            <option value="signature">Signature</option>
          </select>
          <button className="btn" onClick={addField}>
            Add Field
          </button>
        </div>
      </div>

      {fields.map((field, i) => (
        <div className="field-card" key={i}>
          <div className="field-header">
            <input
              className="input"
              type="text"
              placeholder="Label"
              value={field.label}
              onChange={(e) => updateField(i, "label", e.target.value)}
            />
            <div className="field-meta">
              <select
                className="select"
                value={field.type}
                onChange={(e) => updateField(i, "type", e.target.value)}
              >
                <option value="text">Text</option>
                <option value="textarea">Textarea</option>
                <option value="dropdown">Dropdown</option>
                <option value="radio">Radio</option>
                <option value="checkbox">Checkbox</option>
                <option value="searchbox">Search Box</option>
                <option value="file">File</option>
                <option value="camera">Camera</option>
                <option value="signature">Signature</option>
              </select>
              <label className="checkbox-label">
                <input
                  type="checkbox"
                  checked={field.required || false}
                  onChange={(e) => updateField(i, "required", e.target.checked)}
                />
                Required
              </label>
              <button className="btn remove" onClick={() => removeField(i)}>
                Delete
              </button>
            </div>
          </div>

          {["dropdown", "radio", "checkbox", "searchbox"].includes(
            field.type
          ) && (
            <div className="options-section">
              <div className="bulk-toggle">
                <label>
                  <input
                    type="checkbox"
                    checked={field.bulkMode}
                    onChange={() => toggleBulkMode(i)}
                  />{" "}
                  Use bulk input
                </label>
              </div>

              {field.bulkMode ? (
                <textarea
                  className="input"
                  placeholder="Enter comma-separated or newline-separated options"
                  value={field.bulkText || ""}
                  onChange={(e) => handleBulkChange(i, e.target.value)}
                />
              ) : (
                <>
                  <strong>Options:</strong>
                  {field.options?.map((opt, j) => (
                    <div key={j} className="option-row">
                      <input
                        type="text"
                        className="input option-input"
                        value={opt}
                        onChange={(e) => updateOption(i, j, e.target.value)}
                      />
                      <button
                        className="btn remove"
                        onClick={() => removeOption(i, j)}
                      >
                        Remove
                      </button>
                    </div>
                  ))}
                  <button className="btn small" onClick={() => addOption(i)}>
                    Add Option
                  </button>
                </>
              )}
            </div>
          )}
          {field.type === "signature" && (
            <div className="signature-placeholder">
              [Signature field - users will sign when filling form]
            </div>
          )}

          {["dropdown", "searchbox"].includes(field.type) && (
            <div className="branching-section">
              <strong>Branching Options (optional):</strong>
              <select
                className="select"
                value={field.branchingField || ""}
                onChange={(e) =>
                  updateField(i, "branchingField", e.target.value)
                }
              >
                <option value="">Select parent field</option>
                {fields
                  .filter((_, idx) => idx !== i)
                  .map((f, idx) => (
                    <option key={idx} value={f.label}>
                      {f.label}
                    </option>
                  ))}
              </select>

              {field.branchingField && (
                <>
                  {(() => {
                    const parentField = fields.find(
                      (f) => f.label === field.branchingField
                    );
                    if (!parentField) return null;

                    let parentOptions = [];
                    if (
                      parentField.optionMap &&
                      Object.keys(parentField.optionMap).length > 0
                    ) {
                      parentOptions = Object.values(
                        parentField.optionMap
                      ).flat();
                    } else if (parentField.options) {
                      parentOptions = parentField.options;
                    }

                    parentOptions = [...new Set(parentOptions)];

                    return parentOptions.map((opt, k) => (
                      <div key={k} className="option-row">
                        <label>
                          {field.branchingField} = {opt}
                        </label>
                        <textarea
                          placeholder="Enter one option per line or comma-separated"
                          value={field.optionMap?.[opt]?.join("\n") || ""}
                          onChange={(e) =>
                            updateOptionMap(i, opt, e.target.value)
                          }
                          className="input"
                        />
                      </div>
                    ));
                  })()}
                </>
              )}
            </div>
          )}

          {field.type === "camera" && (
            <div className="camera-section">
              <CameraCapture
                onUpload={(file) => {
                  updateField(i, "imageURL", URL.createObjectURL(file));
                }}
              />
              {field.imageURL && (
                <p>
                  <a href={field.imageURL} target="_blank" rel="noreferrer">
                    View Image
                  </a>
                </p>
              )}
            </div>
          )}
        </div>
      ))}

      <button className="btn save-btn" onClick={saveForm}>
        Save Form
      </button>
    </div>
  );
}

export default FormBuilder;
